import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get Slides
export async function getAllSlides(){
    return await http.get(`${apiUrl}/admin/slider-images`);
}
//get Slides
export async function getSlideDetails(slug){
    return await http.get(`${apiUrl}/admin/slider-images/${slug}`);
}
//add Slides photo
export async function addSlides(data){
    return await http.post(`${apiUrl}/admin/slider-images/create`,data);
}
//activate photo Slides
export async function activateSlides(slug){
    return await http.post(`${apiUrl}/admin/slider-images/${slug}/toggle-active`);
}
//delete slider-images
export async function deleteSlides(slug){
    return await http.post(`${apiUrl}/admin/slider-images/${slug}/delete`);
}

//edit slider-images
export async function editSlides(slug,data){
    return await http.post(`${apiUrl}/admin/slider-images/${slug}/update`,data);
}