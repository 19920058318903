import { useState , useEffect , React} from "react";
import { Outlet } from "react-router-dom";
import AuthLayout from './layouts/authLayout/authLayout';


export default function ProtectedRoute() {
    let [authorized , setauthorized] = useState(false);

    useEffect(() =>{
        if(localStorage.getItem('KB_Admin_Token') !== null){
            setauthorized(true)
        }
    } , [authorized]);

    return authorized ? <Outlet/> : <AuthLayout/>;
}