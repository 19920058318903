import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get socials
export async function getAllsocials(){
    return await http.get(`${apiUrl}/admin/socials`);
}
//get Social by slug
export async function getSocialDetails(slug){
    return await http.get(`${apiUrl}/admin/socials/${slug}`);
}
//activate socials
export async function activateSocial(slug){
    return await http.post(`${apiUrl}/admin/socials/${slug}/toggle-active`);
}
//delete socials
export async function removeSocial(slug){
    return await http.post(`${apiUrl}/admin/socials/${slug}/delete`);
}
//add socials
export async function addNewSocial(data){
    return await http.post(`${apiUrl}/admin/socials/create`,data);
}
//edit socials
export async function editSocials(slug,data){
    return await http.post(`${apiUrl}/admin/socials/${slug}/update`,data);
}