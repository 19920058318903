import React from 'react'
import { useState, useRef } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../Admins/admins.css';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import {  editCurrentAdmin, getAdminDetails } from '../../api/authApi';
import Swal from 'sweetalert2';

export default function Profile() {
  const [loading, setLoading] = useState(false);
  const [editedUser, seteditedUser] = useState({ name: ' ', username: " ", email: ' ', password: '', password_confirmation: '' });
  const [errors, setErrors] = useState({ name: '', username: '', email: '', password: '', password_confirmation: '' })
  const [showPassword, setShowpassword] = useState(false);
  const [showcurrentPassword, setShowcurrentPassword] = useState(false);
  const [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
  let passwordRef = useRef();
  let cpasswordRef = useRef();
  let currentpasswordRef = useRef();
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(()=>{
        getAdminDetails().then((res)=>{
              let useCopy = { ...editedUser }
              useCopy = res.data.data
              seteditedUser(useCopy)
          })
      //eslint-disable-next-line
  },[])

  function editAdminHandler(e) {
      let User = { ...editedUser };
      let error = { ...errors };
      User[e.target.name] = e.target.value.trim();
      error[e.target.name] = ' ';
      setErrors(error)
      seteditedUser(User);
  }

  // password showing handler 
  function showPasswordHandler(x) {
      if (x === 'pass') {
          if (passwordRef.current.type === 'password') {
              passwordRef.current.type = 'text';
              setShowpassword(true)
          } else {
              passwordRef.current.type = 'password';
              setShowpassword(false)
          }
      } else if (x === 'cPass') {
          if (cpasswordRef.current.type === 'password') {
              cpasswordRef.current.type = 'text';
              setshowPasswordConfirm(true)
          } else {
              cpasswordRef.current.type = 'password';
              setshowPasswordConfirm(false)
          }
      }else if (x === 'currentPass') {
        if (currentpasswordRef.current.type === 'password') {
            currentpasswordRef.current.type = 'text';
            setShowcurrentPassword(true)
        } else {
            currentpasswordRef.current.type = 'password';
            setShowcurrentPassword(false)
        }
    }
  }

  function submitaddAdmin(e) {
      e.preventDefault();
      setLoading(true);
      editCurrentAdmin(editedUser).then(()=>{
          setLoading(false)
          Swal.fire({
              icon: 'success',
              text: "تم تعديل البيانات بنجاح",
              timer: 2500,
              showConfirmButton: false
            })
          }).catch((error) => {
              setLoading(false)
              let err = { ...errors }
              Object.entries(error.response.data.data).map(([key, value]) => {
                  err[key] = value;
                  setErrors(err);
                  return  true;
              });
      });
  }


  return (
    <section style={{ "direction": "rtl" }}>
    <div className='sectionWrapper'>
        <h4> تفاصيل الحساب </h4>
        <form  className='px-3 pt-4' onSubmit={submitaddAdmin}  autoComplete="off">
            {/* project name */}
            <div className="row g-5 mb-4">
                <div className="col-12 col-lg-6">
                    <div className="form-outline ">
                        <label className="form-label labels" htmlFor="name">  الاسم </label>
                        <input value={editedUser.name ?? ''} onChange={editAdminHandler} type="text" name="name" id="name" className="form-control" required/>
                    </div>
                <div className='text-danger'>{errors.name}</div>
                </div>
            </div>
            <div className="row g-5 mb-4">
                <div className="col-12 col-lg-6">
                    <div className="form-outline ">
                        <label className="form-label labels" htmlFor="email">  البريد الالكتروني </label>
                        <input value={editedUser.email ?? ''} onChange={editAdminHandler} type="text" name="email" id="email" className="form-control" required/>
                    </div>
                    <div className='text-danger'>{errors.email}</div>
                </div>
            </div>
            <div className="row g-5 mb-4">
                <div className="col-12 col-lg-6">
                    <div className="form-outline ">
                        <label className="form-label labels" htmlFor="current_password">  كلمة المرور الحالية</label>
                        <div className='position-relative'>
                            <input  autoComplete="off" onChange={editAdminHandler} ref={currentpasswordRef} type="password" name="current_password" id="current_password" className="form-control"  />
                            {!showcurrentPassword ?
                                <FontAwesomeIcon icon={faEye} className='eyeIcon' onClick={() => showPasswordHandler('currentPass')} /> :
                                <FontAwesomeIcon icon={faEyeSlash} className='eyeIcon' onClick={() => showPasswordHandler('currentPass')} />
                            }
                        </div>
                    </div>
                    <div className='text-danger'>{errors.current_password}</div>
                </div>
            </div>
            <div className="row g-5 mb-4">
                <div className="col-12 col-lg-6">
                    <div className="form-outline ">
                        <label className="form-label labels" htmlFor="password">  كلمة المرور الجديدة</label>
                        <div className='position-relative'>
                            <input autoComplete="off"  onChange={editAdminHandler} ref={passwordRef} type="password" name="password" id="password" className="form-control"  />
                            {!showPassword ?
                                <FontAwesomeIcon icon={faEye} className='eyeIcon' onClick={() => showPasswordHandler('pass')} /> :
                                <FontAwesomeIcon icon={faEyeSlash} className='eyeIcon' onClick={() => showPasswordHandler('pass')} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-5 mb-4">
                <div className="col-12 col-lg-6">
                    <div className="form-outline ">
                        <label className="form-label labels" htmlFor="password_confirmation">   تاكيد كلمة المرور الجديدة</label>
                        <div className='position-relative'>
                            <input autoComplete="off"  onChange={editAdminHandler} ref={cpasswordRef} type="password" name="password_confirmation" id="password_confirmation" className="form-control"  />
                            {!showPasswordConfirm ?
                                <FontAwesomeIcon icon={faEye} className='eyeIcon' onClick={() => showPasswordHandler('cPass')} /> :
                                <FontAwesomeIcon icon={faEyeSlash} className='eyeIcon' onClick={() => showPasswordHandler('cPass')} />
                            }
                        </div>
                    </div>
                    <div className='text-danger'>{errors.password}</div>
                </div>
            </div>

            <div className="mt-5">
                <div className="me-auto">
                    {!loading ?
                        <input className='createBtnContainer mb-2 py-1' type='submit' value='حفظ ' />
                        :
                        <button className='createBtnContainer mb-2 py-1' disabled>
                            جاري الحفظ
                            <span className="spinner-border text-light spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        </button>
                    }
                </div>
            </div>
        </form>
    </div>
</section>
  )
}
