import React from 'react'
import { Route, Routes } from "react-router-dom";
import "./Home.css"
import Header from "../Header/Header";
import Aside from "./../Aside/Aside";
import Profile from './../Profile/Profile';
import Projects from '../Projects/Projects';
import CreateProject from '../Projects/CreateProject';
import Types from '../Types/Types';
import Areas from '../Areas/Areas';
import Gallery from '../Gallery/Gallery';
import EditProject from '../Projects/EditProject';
import Blogs from '../Blogs/Blogs';
import CreateBlog from '../Blogs/CreateBlog';
import EditBlog from '../Blogs/EditBlog';
import Alladmins from './../Admins/all-admins';
import AddAdmin from './../Admins/add-admin';
import EditAdmin from './../Admins/edit-admin';
import AddGallery from './../Gallery/addGallery';
import EditGallery from './../Gallery/editGallery';
import CompanyGoals from './../WebsiteSettings/company-goals/CompanyGoals';
import AddGoal from './../WebsiteSettings/company-goals/addGoal';
import EditGoal from './../WebsiteSettings/company-goals/editGoal';
import Socials from './../socials/socials';
import AddSocial from './../socials/addSocial';
import EditSocial from './../socials/editSocial';
import ContactUs from './../contactUs/contactUs';
import Partners from './../partners/partners';
import AddPartner from './../partners/addPartner';
import EditPartner from './../partners/editPartner';
import AboutUs from './../pages/aboutUs/aboutUs';
import EditAdvantage from './../pages/aboutUs/editAdvantage';
import AddAdvantage from './../pages/aboutUs/addAdvantage';
import Slides from './../WebsiteSettings/sliderImages/sliders';
import AddSliderImg from './../WebsiteSettings/sliderImages/addSLide';
import EditSliderImg from './../WebsiteSettings/sliderImages/editSlide';
import GeneralSettings from './../WebsiteSettings/generalSettings/generalSettings';
import Statistics from './../WebsiteSettings/statistics/statistics';
import AddStatistic from './../WebsiteSettings/statistics/addStatistic';
import EditStatistic from './../WebsiteSettings/statistics/editStatistic';
import Headers from './../WebsiteSettings/headers/headers';
import Overview from './../overview/overview';

export default function Home() {
    return (
    <React.Fragment>
        <section className='home'>
            <div className="container-fluid d-flex flex-row-reverse m-0 p-0">
                    <div className='asideHome p-0'>
                        <Aside />   
                    </div>
                    <div className='contentHome'>
                        <Header/>
                        <div className='dash_wrapper'>
                            <Routes>
                                <Route path='' element={<Overview/>} />
                                <Route path='profile' element={<Profile/>} />
                                <Route path='all-projects/*' >
                                    <Route path='' element={<Projects/>} />
                                    <Route path='create-project' element={<CreateProject/>}/>
                                    <Route path='edit-project/:slug' element={<EditProject />} />
                                </Route>
                                <Route path='admins/*' >
                                    <Route path='' element={<Alladmins/>} />
                                    <Route path='add-admin' element={<AddAdmin/>}/>
                                    <Route path='edit-admin/:slug' element={<EditAdmin/>}/>
                                </Route>
                                <Route path='all-blogs/*' >
                                    <Route path='' element={<Blogs/>} />
                                    <Route path='create-blog' element={<CreateBlog/>}/>
                                    <Route path='edit-blog/:slug' element={<EditBlog />} />
                                </Route>
                                <Route path='project-types' element={<Types />} />
                                <Route path='areas' element={<Areas />} />
                                <Route path='gallery/*' >
                                    <Route path='' element={<Gallery/>} />
                                    <Route path='add-image' element={<AddGallery/>}/>
                                    <Route path='edit-image/:slug' element={<EditGallery />} />
                                </Route>
                                <Route path='profile' element={<Profile/>} />
                                <Route path='contact-us' element={<ContactUs/>} />

                                <Route path='website-settings/*' >
                                    <Route path='goals/*' >
                                        <Route path='' element={<CompanyGoals/>} />
                                        <Route path='add-goal' element={<AddGoal/>} />
                                        <Route path='edit-goal/:slug' element={<EditGoal/>} />
                                    </Route>
                                    <Route path='socials/*' >
                                        <Route path='' element={<Socials/>} />
                                        <Route path='add-social' element={<AddSocial/>} />
                                        <Route path='edit-social/:slug' element={<EditSocial/>} />
                                    </Route>
                                    <Route path='partners/*' >
                                        <Route path='' element={<Partners/>} />
                                        <Route path='add-partner' element={<AddPartner/>} />
                                        <Route path='edit-partner/:slug' element={<EditPartner/>} />
                                    </Route>
                                    <Route path='slider-images/*' >
                                        <Route path='' element={<Slides/>} />
                                        <Route path='add-slide' element={<AddSliderImg/>} />
                                        <Route path='edit-slide/:slug' element={<EditSliderImg/>} />
                                    </Route>
                                    {/* <Route path='advantages' element={<Advantages/>} /> */}
                                    <Route path='general-settings' element={<GeneralSettings/>} />
                                    <Route path='statistics/*' >
                                        <Route path='' element={<Statistics/>} />
                                        <Route path='add-statistic' element={<AddStatistic/>} />
                                        <Route path='edit-statistic/:slug' element={<EditStatistic/>} />
                                    </Route>
                                </Route>
                                
                                <Route path='pages/*' >
                                    <Route path='about-us' element={<AboutUs/>} />
                                    <Route path='headers' element={<Headers/>} />
                                    <Route path='advantages/*' >
                                        <Route path='add-advantage' element={<AddAdvantage/>} />
                                        <Route path='edit-advantage/:slug' element={<EditAdvantage/>} />
                                    </Route>
                                </Route>
                            </Routes>
                        </div>
                    </div>
            </div>
        </section>
    </React.Fragment>
    )
}
