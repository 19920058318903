import React from 'react';
// import axios from 'axios';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'
import Tooltip from "@material-ui/core/Tooltip";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { basename } from '../../config/config';


export default function Header() {
  // const AdminToken = `Bearer ` + localStorage.getItem('KB_Admin_Token');
  let AdminName = localStorage.getItem('KB_Admin_Name');
  const navigate = useNavigate();
  
  // useEffect(() => {
  //   axios({
  //     url: `${process.env.REACT_APP_API}/admin`,
  //     method: 'get',
  //     headers: {
  //         Authorization: AdminToken,
  //     }
  //   })
  //   .then( res =>{
  //     console.log(res)
  //   })
  //   .catch( err =>
  //     console.log(err)
  //   )
  // }, []);

  function logoutHandler(){
    localStorage.removeItem("KB_Admin_Token");
    localStorage.removeItem("KB_Admin_Name");
    navigate(`/${basename}`);
  }

  return (
    <div className='headerContainer shadow'>
      <div className=' h-100'>
          <div className='d-flex align-items-center h-100'>
            <Tooltip title="تسجيل الخروج">
              <div className='logOutIcon' onClick={logoutHandler}>
                  <FontAwesomeIcon icon={faSignOut}/>
              </div>
            </Tooltip>
            <Tooltip title='تفاصيل الحساب'>
              <div className='profilePicture mx-3'>
                <Link to={`${basename}/dashboard/profile`}>
                  <div>
                    {AdminName.charAt(0)}
                  </div>
                </Link>
              </div>
            </Tooltip>            
          </div>
      </div>
      
    </div>
  )
}
