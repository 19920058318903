import { apiUrl } from '../config/config';
import { http } from './../config/http';

export async function getallTypes() {
    return await http.get(`${apiUrl}/admin/types`);
}
export async function getType(slug) {
    return await http.get(`${apiUrl}/admin/types/${slug}`);
}
export async function addType(data){
    return await http.post(`${apiUrl}/admin/types/create`,data);
}
export async function editType(slug,data){
    return await http.post(`${apiUrl}/admin/types/${slug}/update`,data);
}
export async function deleteType(slug){
    return await http.post(`${apiUrl}/admin/types/${slug}/delete`);
}