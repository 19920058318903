import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get projects
export async function getAllProjects(){
    return await http.get(`${apiUrl}/admin/projects`);
}
//get project by slug
export async function getProjectDetails(slug){
    return await http.get(`${apiUrl}/admin/projects/${slug}`);
}
//activate projects
export async function activateProjects(slug){
    return await http.post(`${apiUrl}/admin/projects/${slug}/toggle-active`);
}
//delete projects
export async function removeProject(slug){
    return await http.post(`${apiUrl}/admin/projects/${slug}/delete`);
}
//add projects
export async function addNewProject(data){
    return await http.post(`${apiUrl}/admin/projects/create`,data);
}
//edit projects
export async function editProject(slug,data){
    return await http.post(`${apiUrl}/admin/projects/${slug}/update`,data);
}