import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get statistics
export async function getAllStatistics(){
    return await http.get(`${apiUrl}/admin/statistics`);
}
//get Statistic by slug
export async function getStatisticDetails(slug){
    return await http.get(`${apiUrl}/admin/statistics/${slug}`);
}
//activate statistics
export async function activateStatistic(slug){
    return await http.post(`${apiUrl}/admin/statistics/${slug}/toggle-active`);
}
//delete statistics
export async function removeStatistic(slug){
    return await http.post(`${apiUrl}/admin/statistics/${slug}/delete`);
}
//add statistics
export async function addNewStatistic(data){
    return await http.post(`${apiUrl}/admin/statistics/create`,data);
}
//edit statistics
export async function editStatisticDetails(slug,data){
    return await http.post(`${apiUrl}/admin/statistics/${slug}/update`,data);
}