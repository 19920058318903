
import { apiUrl } from '../config/config.js';
import { http } from './../config/http';
import axios from 'axios'

export async function userLogin(data){
    return await axios.post(`${apiUrl}/admin/login`,data);
}
//send email to reset password
export async function userCheckEmail(data){
    return await axios.post(`${apiUrl}/admin/reset-password`,data);
}
//send pin code and new password
export async function userResetPassword(data){
    return await axios.post(`${apiUrl}/admin/confirm-password`,data);
}
// get users
export async function getAdmins(){
    return await http.get(`${apiUrl}/admin`);
}
// get user by slug
export async function getUser(slug){
    return await http.get(`${apiUrl}/admin/${slug}`);
}
// add users
export async function addNewAdmin(data){
    return await http.post(`${apiUrl}/admin/create`,data);
}
// edit user
export async function editAdmin(data,slug){
    return await http.post(`${apiUrl}/admin/${slug}/update`,data);
}
// delete user
export async function removeAdmin(slug){
    return await http.post(`${apiUrl}/admin/${slug}/delete`);
}

//get admin data 
export async function getAdminDetails(){
    return await http.get(`${apiUrl}/admin/profile`);
}
// edit user
export async function editCurrentAdmin(data){
    return await http.post(`${apiUrl}/admin/update`,data);
}