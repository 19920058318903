import { apiUrl } from '../config/config';
import { http } from './../config/http';

//get Goals
export async function getAllGoals(){
    return await http.get(`${apiUrl}/admin/goals`);
}
//get Goal details
export async function getGoalDetail(slug){
    return await http.get(`${apiUrl}/admin/goals/${slug}`);
}
//activate goals
export async function activateGoals(slug){
    return await http.post(`${apiUrl}/admin/goals/${slug}/toggle-active`);
}
//delete goals
export async function deleteGoals(slug){
    return await http.post(`${apiUrl}/admin/goals/${slug}/delete`);
}
//create goals
export async function addNewGoal(data){
    return await http.post(`${apiUrl}/admin/goals/create`,data);
}
//edit goals
export async function editGoal(slug,data){
    return await http.post(`${apiUrl}/admin/goals/${slug}/update`,data);
}
