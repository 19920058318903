import React from 'react';
import logo from '../../Images/kbLogo.png'

export default function Overview() {
    return (
        <section style={{ "direction": "rtl" }}>
            <div className='sectionWrapper overView'>
                <img src={logo} alt='alkawthar'/>
                <h1>لوحة تحكم خليج الكوثر</h1>
            </div>
        </section>
    )
}
