import React from 'react'
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import { basename } from './../../config/config';
import Login from './../../Components/Login/Login';
import ResetPassword from './../../Components/Login/resetPassword';
import '../../Components/Login/Login.css';
export default function AuthLayout() {
  return (
        <React.Fragment>
			<div className="section" id='loginPage'>
				<div className="container">
					<div className="row full-height justify-content-center">
						<div className="col-12 text-center align-self-center py-5">
							<div className="section pb-5 pt-5 pt-sm-2 text-center">
								<div className="card-3d-wrap mx-auto">
									<div className="card-3d-wrapper">
										<div className="card-front">
											<div className="center-wrap">
                                            <Routes>
                                                <Route path={`${basename}/`} element={<Login />} />
                                                <Route path={`${basename}/reset-password`} element={<ResetPassword />} />
                                                <Route path={`${basename}/*`} element={<Login />} />
                                            </Routes> 
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
    
  )
}
